var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading,"variant":"transparent","opacity":"0.99","blur":"5px","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon-controller',{attrs:{"font-scale":"3","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v(" กรุณารอสักครู่... ")])],1)]},proxy:true}])},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-start w-100"},[_c('div',{staticClass:"card-title"},[_vm._v("แบนเนอร์")]),_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-end"},[_c('Create',{attrs:{"gameCategories":_vm.gameCategories,"gameProducts":_vm.gameProducts,"games":_vm.games},on:{"getGames":_vm.getGames,"getProducts":_vm.getGameProducts,"submit":_vm.upsert}}),_c('b-form-input',{staticClass:"mt-1",attrs:{"placeholder":"ค้นหา"},on:{"input":_vm.search}})],1)])]),_c('b-table',{staticClass:"mt-2 position-relative items-center",attrs:{"striped":"","hover":"","small":"","responsive":"","show-empty":"","per-page":_vm.perPage,"items":_vm.items.data,"fields":_vm.fields,"fixed":""},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('div',{staticClass:"p-2 text-center"},[_c('img',{attrs:{"src":"/box.png","alt":"empty","height":"40px"}}),_vm._v(" "+_vm._s(scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล')+" ")])]}},{key:"cell(image_link)",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.image && _vm.agent ? ((_vm.agent.site_admin) + "/img/announcements/" + (item.image)) : item.image_link,"alt":"Image","width":"150"}})]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created_at))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"id":"dropdown-3","text":"Success","variant":"success"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"far fa-edit mr-50"}),_vm._v(" จัดการ ")]},proxy:true}],null,true)},[_c('Edit',{attrs:{"data":item,"gameCategories":_vm.gameCategories,"gameProducts":_vm.gameProducts,"games":_vm.games},on:{"getGames":_vm.getGames,"getProducts":_vm.getGameProducts,"submit":_vm.upsert}}),_c('b-dropdown-item',{on:{"click":function($event){return _vm.destroy(item.id)}}},[_c('i',{staticClass:"fas fa-trash mr-1 text-danger"}),_vm._v(" ลบ ")])],1)]}}])}),_c('div',{staticClass:"card-body d-flex justify-content-between flex-wrap pt-0"},[_c('b-form-group',{staticClass:"text-nowrap mb-md-0 mr-1",attrs:{"label":"Per Page","label-cols":"8","label-align":"left","label-size":"sm","label-for":"sortBySelect"}},[_c('b-form-select',{attrs:{"id":"perPageSelect","size":"sm","inline":"","options":_vm.pageOptions},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('div',[_c('b-pagination',{staticClass:"mb-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function($event){return _vm.getItems('')}},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }